import Swiper from 'swiper/bundle';
import { Mousewheel } from 'swiper/modules';
export default class Carousel {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    this.setOptions();
    new Swiper(this.element, this.options);
  }

  setOptions() {
    if ('normaleAllo' in this.element.dataset) {
      this.options = {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },

        pagination: {
          el: this.element.querySelector('.swiper-pagination'),
          clickable: true,
        },
      };
    }
  }
}
