import LocomotiveScroll from 'locomotive-scroll';

export default class Locomotive {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    const scroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
    });

    window.addEventListener('progressEvent', (e) => {
      const { target, progress } = e.detail;
      console.log(`target: ${target}`, `progress: ${progress}`);
    });
  }
}
