import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default class Experience {
  constructor() {
    const hero = document.querySelector('.hero .wrapper');

    // Check if hero exists before accessing clientWidth and clientHeight
    if (hero) {
      this.sizes = {
        width: hero.clientWidth,
        height: hero.clientHeight,
      };
    } else {
      console.error(
        'Hero element not found. Please check your HTML structure.'
      );
      return; // Exit constructor if hero is not found
    }

    this.canvas = document.querySelector('.webgl');
    this.gltfLoader = new GLTFLoader();

    // Scene
    this.scene = new THREE.Scene();
    this.clock = new THREE.Clock();

    this.init();
  }

  init() {
    window.addEventListener('resize', this.resize.bind(this));

    this.createCamera();
    this.createLights(); // Add lighting
    this.createObjects();
    this.createRenderer();
    this.animate();
  }
  createCamera() {
    // Camera
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.sizes.width / this.sizes.height
    );
    this.camera.position.z = 3;

    this.scene.add(this.camera);

    this.controls = new OrbitControls(this.camera, this.canvas);
    this.controls.enableDamping = true;
    this.controls.enablePan = false;
    this.controls.enableZoom = false;
  }

  createLights() {
    // Add ambient light for general lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6); // Soft white light
    this.scene.add(ambientLight);

    // Add directional light for more defined shadows and highlights
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 5, 5); // Position the light
    this.scene.add(directionalLight);
  }

  createRenderer() {
    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
    });
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    this.renderer.render(this.scene, this.camera);
  }

  createObjects() {
    const path = window.location.pathname; // Get the current page path

    this.gltfLoader.load('assets/models/ac/isla.gltf', (gltf) => {
      this.model = gltf.scene;
      this.model.scale.set(1, 1, 1); // Adjust scale of the model

      // Conditional position based on the page
      if (path.includes('a_propos.html')) {
        // Move the model to the right on a_propos.html
        this.model.position.set(1.5, -0.2, -1); // Example: move it to the right by 1 unit
      } else {
        // Default position for other pages
        this.model.position.set(0, 0, 0); // Center the model
      }

      this.scene.add(this.model); // Add the model to the scene
    });
  }

  animate() {
    const elapsedTime = this.clock.getElapsedTime();

    // // Animate the cube if it exists
    // if (this.model) {
    //   this.model.rotation.x = elapsedTime;
    // }

    // // Animate the blue cube if it exists
    // if (this.cube2) {
    //   this.cube2.rotation.y = elapsedTime;
    // }

    // Animate the 3D model
    if (this.model) {
      this.model.rotation.y = elapsedTime;
    }

    this.controls.update();
    this.renderer.render(this.scene, this.camera);

    window.requestAnimationFrame(this.animate.bind(this));
  }

  resize() {
    // Update sizes
    this.camera.aspect = this.sizes.width / this.sizes.height;
    this.camera.updateProjectionMatrix();

    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    this.renderer.render(this.scene, this.camera);
  }
}
