import ComponentFactory from './ComponentFactory';
import Experience from './Experience';
import Icons from './utils/Icons';
import Locomotive from './components/Locomotive';
class Main {
  constructor() {
    this.init();
  }

  init() {
    document.documentElement.classList.add('has-js');

    Icons.load();
    new Experience();
    new Locomotive();

    // Sélectionner les barres de progression
    const progressBars = document.querySelectorAll(
      '.progress_1 .progress-80, .progress_2 .progress-70'
    );

    // Configuration de l'observer
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Ajouter la classe animate lorsque l'élément entre dans le viewport
            entry.target.closest('.barre').classList.add('animate');
            observer.unobserve(entry.target); // Arrêter l'observation après l'animation
          }
        });
      },
      { threshold: 0.5 }
    ); // Lancer l'animation lorsque 50% de l'élément est visible

    // Attacher l'observer à chaque barre de progression
    progressBars.forEach((bar) => {
      observer.observe(bar);
    });

    new ComponentFactory();
  }
}

new Main();
