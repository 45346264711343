export default class Accordion {
  constructor(element) {
    this.element = element;
    this.accordion = this.element.querySelectorAll('.js-header');
    this.options = {
      notClosing: false,
    };

    this.init();
  }

  init() {
    this.setOptions();
    for (let i = 0; i < this.accordion.length; i++) {
      const element = this.accordion[i];

      element.addEventListener('click', this.accordionSelect.bind(this));
    }
  }
  setOptions() {
    if ('notClosing' in this.element.dataset) {
      this.options.notClosing = true;
    }
  }
  accordionSelect(event) {
    if (this.options.notClosing == true) {
      event.currentTarget.classList.toggle('is-active');
    } else {
      if (event.currentTarget.classList.contains('is-active')) {
        event.currentTarget.classList.remove('is-active');
      } else {
        for (let i = 0; i < this.accordion.length; i++) {
          const element = this.accordion[i];
          element.classList.remove('is-active');
        }
        event.currentTarget.classList.add('is-active');
      }
    }
  }
}
